// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

const greeting = {
  title: "Sanjay Singh",
  logo_name: "SanjaySingh",
  nickname: "",
  subTitle:
    "As a dedicated web developer with extensive experience in crafting e-commerce projects from inception. My passion lies in creating end-to-end solutions that foster sustainable and scalable social and technical systems, aiming to make a significant impact."
  , resumeLink:
    "https://drive.google.com/file/d/1C_0_2hiBYnuaP6a0Y4nOXTziqqaDih-t/view",
  portfolio_repository: "https://github.com/singhsanjayrd/personal",
  githubProfile: "https://github.com/singhsanjayrd",
};

//SEO Related settings
const seo = {
  title: "Sanjay's Portfolio",
  description: greeting.subTitle,
  og: {
    title: "Sanjay Singh Portfolio",
    type: "website",
    url: "https://singhsanjay.com/",
  },
};

//Home Page


const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/singhsanjayrd",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/singhsanjayrd/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/@ShoonyaScope",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:singhsanjayrd@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "X-Twitter",
    link: "https://twitter.com/singhsanjayrd",
    fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/singhsanjayrd/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/singhsanjayrd/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Developing mobile applications using Flutter, React Native and solo android apps using Kotlin",
        "⚡ Creating application backend in Node, Express & Flask",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "simple-icons:gatsby",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/singhsanjayrd/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/singhsanjayrd",
    }
  ],
};

const degrees = {
  degrees: [
    {
      title: "Sir M. Visvesvaraya Institute of Technology, Bangalore",
      subtitle: "B.Tech. in Computer Engineering",
      logo_path: "smvit.png",
      alt_name: "sirmvit",
      duration: "2011 - 2015",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on UX, Cloud Services and Full Stack Development.",
      ],
      website_link: "https://sirmvit.edu",
    }
  ],
};

const certifications = {
  certifications: [
    {
      title: "Fullstack Development",
      subtitle: "- Jogesh Muppala",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
    {
      title: "Kuberenetes on GCP",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/",
      alt_name: "GCP",
      color_code: "#4285F499",
    },

    {
      title: "Cloud Architecture",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work",
  description:
    "I have extensive experience leading UI teams in GreenField Projects, focusing on scalability, code quality, and product adoption and monetization across diverse organizations. I am passionate about engaging with the developer community and regularly participate in events, conferences, and meetups.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Senior Software Engineer",
          company: "Walmart",
          company_url: "https://tech.walmart.com/content/walmart-global-tech/en_us.html",
          logo_path: "walmart-logo.png",
          duration: "May 2020 - Present",
          location: "Bangalore, KA, India",
          description:
            `Walmart Spatial Platform - Designed the architecture, led UI team to develop system from scratch, keeping quality, scalability, leverage and monetization of app at the core. \n
            Exposed app to be used as Microfrontend as well as Npm Library while catering to the consumers’ requirements.
            Created and maintaining community for web developers across org.`,
          color: "#000000",
        },
        {
          title: "Tech Lead (UI)",
          company: "Falabella",
          company_url: "https://falabellaindia.com/",
          logo_path: "falabella-logo.png",
          duration: "Oct 2018 - May 2020",
          location: "Bangalore, KA, India",
          description:
            `Sodimac - Redesigned existing monolithic website to a highly scalable, Multilingual, Isomorphic, responsive website using latest tech-stack. Ex:- React, Next, Fastify, Google cloud, kafka etc.\n
            Active participation in code reviews, solution design, and ensuring code-base is well maintained with quality code.\n
            Responsible for web optimizations, identifying and fixing performance bottlenecks.`,
          color: "#0879bf",
        },
        {
          title: "Senior Software Engineer",
          company: "Tesco",
          company_url: "https://www.tescobengaluru.com",
          logo_path: "tesco-logo2.png",
          duration: "Aug 2015 - Oct 2018",
          location: "Bangalore, KA, India",
          description:
            `Tesco Groceries - Designed and developed highly performant, responsive, multilingual isomorphic website primarily using React, Node, Express, Rest APIs. \n.
            Tesco Clubcard- developed new responsive Club-card website using React, Apollo Client and removing the existing legacy monolithic system and integrated with enterprise apis’.\n
            Actively took sessions on various topics enabling other team members.\n
            Played key role in development, go live pre-requisites like setting up monitoring and alerts,traffic distribution, testing and rollout strategy.
            `,
          color: "#9b1578",
        }
      ],
    }
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Web Projects and deploy them using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "neuro-symbolic-sudoku-solver",
      name: "Neuro-Symbolic Sudoku Solver",
      createdAt: "2023-07-02T00:00:00Z",
      description: "Paper published in KDD KiML 2023",
      url: "https://arxiv.org/abs/2307.00653",
    },
    {
      id: "mdp-diffusion",
      name: "MDP-Diffusion",
      createdAt: "2023-09-19T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/mdp-diffusion/",
    },
    {
      id: "consistency-models",
      name: "Consistency Models",
      createdAt: "2023-10-12T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/consistency-models/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "sanjay-profile.jpg",
    description:
      "Please reach out to me on following social media handles. I can help you with React, Angular, Node, Cloud and Opensource Development.",
  },

  addressSection: {
    title: "Address",
    subtitle: "Electronic City, Bangalore, KA, India 560100",
    locality: "Electronic City",
    country: "India",
    region: "Karnataka",
    postalCode: "560100",
    streetAddress: "",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/YphbdtCQFynRgdGM8",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
